<template>
  <b-container fluid class="four-buckets-report-chart-wrapper">
    <template v-if="apiData && !Array.isArray(this.apiData) && Object.keys(apiData).length > 0">
      <!-- /* Todo: replace currencysymbot*/ -->
      <treemap-nested :data="chartData"
                      :currencySymbol="currentCOA && currentCOA.currency ? currentCOA.currency : 'kr'"
      />
    </template>
    <template v-else>
      <empty-graph :messsage="$t('reports.balance.four_buckets_report.no_data_in_chart')"/>
    </template>
  </b-container>
</template>

<script>
import { mapState } from 'vuex'
import treemapNested from '@/components/d3charts/treemap-nested/treemap-nested.vue'
import EmptyGraph from '@/components/common/EmptyGraph'

export default {
  name: 'FourBucketsChartSummaryAssetAllocation',
  props: ['api-data'],
  components: { treemapNested, EmptyGraph },
  data () {
    return {
      chartData: null,
      colors: ['#FEC600', '#ff7c43', '#f95d6a', '#d45087'],
      textColors: ['#000', '#fff', '#fff', '#fff'],
      translationPath: 'reports.balance.four_buckets_report.submenu.'
    }
  },
  computed: {
    ...mapState('user', ['currentCOA'])
  },
  methods: {
    async fillChart () {
      if (this.apiData === null || this.apiData.length === 0) {
        return false
      }
      const buckets = ['conservative', 'moderate', 'aggressive', 'speculation']
      const data = []
      let aTmpData = []

      buckets.map((bucket, index) => {
        aTmpData = {
          label: this.$t(this.translationPath + bucket),
          color: this.colors[index],
          textColor: this.textColors[index],
          id: bucket,
          children: [
            {
              label: this.$t('reports.balance.asset_allocation_report.common.equities'),
              id: bucket + '_equities',
              color: this.colors[index],
              textColor: this.textColors[index],
              amount: this.apiData.bucket_totals[bucket].asset_allocation.sums.equities.toFixed(0),
              percent: this.apiData.bucket_totals[bucket].asset_allocation.percentages.equities.toFixed(2)
            },
            {
              label: this.$t('reports.balance.asset_allocation_report.common.cash'),
              id: bucket + '_cash',
              color: this.colors[index],
              textColor: this.textColors[index],
              amount: this.apiData.bucket_totals[bucket].asset_allocation.sums.cash.toFixed(0),
              percent: this.apiData.bucket_totals[bucket].asset_allocation.percentages.cash.toFixed(2)
            },
            {
              label: this.$t('reports.balance.asset_allocation_report.common.gold'),
              id: bucket + '_gold',
              color: this.colors[index],
              textColor: this.textColors[index],
              amount: this.apiData.bucket_totals[bucket].asset_allocation.sums.gold.toFixed(0),
              percent: this.apiData.bucket_totals[bucket].asset_allocation.percentages.gold.toFixed(2)
            },
            {
              label: this.$t('reports.balance.asset_allocation_report.common.fixed_income'),
              id: bucket + '_fixed_income',
              color: this.colors[index],
              textColor: this.textColors[index],
              amount: this.apiData.bucket_totals[bucket].asset_allocation.sums.fixed_income.toFixed(0),
              percent: this.apiData.bucket_totals[bucket].asset_allocation.percentages.fixed_income.toFixed(2)
            },
            {
              label: this.$t('reports.balance.asset_allocation_report.common.alternatives'),
              id: bucket + '_alternatives',
              color: this.colors[index],
              textColor: this.textColors[index],
              amount: this.apiData.bucket_totals[bucket].asset_allocation.sums.alternatives.toFixed(0),
              percent: this.apiData.bucket_totals[bucket].asset_allocation.percentages.alternatives.toFixed(2)
            },
            {
              label: this.$t('reports.balance.asset_allocation_report.common.other'),
              id: bucket + '_other',
              color: this.colors[index],
              textColor: this.textColors[index],
              amount: this.apiData.bucket_totals[bucket].asset_allocation.sums.other.toFixed(0),
              percent: this.apiData.bucket_totals[bucket].asset_allocation.percentages.other.toFixed(2)
            },
            {
              label: this.$t('reports.balance.four_buckets_report.table.not_defined'),
              id: bucket + '_not_defined',
              color: this.colors[index],
              textColor: this.textColors[index],
              amount: this.apiData.bucket_totals[bucket].asset_allocation.sums.not_defined.toFixed(0),
              percent: this.apiData.bucket_totals[bucket].asset_allocation.percentages.not_defined.toFixed(2)
            }
          ]
        }
        data.push(aTmpData)
      })

      this.chartData = data
    }
  },
  watch: {
    currentCOA: {
      deep: true,
      handler () {
        this.fillChart()
      }
    },
    apiData: {
      deep: true,
      handler () {
        this.fillChart()
      }
    }
  },
  created () {
    this.fillChart()
  }
}
</script>
