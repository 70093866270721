<template>
  <b-container fluid class="asset-allocation-report-table px-0">
    <b-row no-gutters>
      <b-col>
        <b-card class="main-gradient-content-card borderlight pt-3 mx-xl-auto">
          <template v-slot:header>
            <h2>{{ $t( translationPathTable + 'summary_table_title')}} </h2>
          </template>

          <b-col class="col-md-8">
            <i18n :path="translationPathHeader + 'description_summary'" tag="p" class="text-gray">
              <b-link slot="link" href="https://forum.spirecta.se/t/fyra-hinkar-principen-en-fordjupning/68" target="_blank">{{ $t('common.read_in_manual') }}</b-link>
            </i18n>
          </b-col>

          <b-col class="px-0 mb-5">
            <b-table class="spirecta-simple-table four-buckets-table four-buckets-summary-table" show-empty hover responsive striped foot-clone
              stacked="lg"
              :items="rows"
              :fields="fieldValue"
              :busy="busy || !currentCOA.locale"
              :empty-text="$t(translationPathTable + 'empty_text')"
            >

              <template v-slot:table-busy>
                <loader/>
              </template>
              <template v-slot:cell(bucket)="row">
                <b-link :to="`/reports/balance/four-buckets/${row.value}`">{{ $t(translationPath + row.value)}}</b-link>
              </template>
              <template v-slot:cell(sum_assets)="row">
                {{ row.value | formatAmount(currentCOA.locale, currentCOA.currency, currentCOA.currency_iso, true, 0) }}
              </template>
              <template v-slot:cell(total_asset_percentage)="row">
                {{ row.value | formatAmount(currentCOA.locale, currentCOA.currency, currentCOA.currency_iso, false, 1) }} %
              </template>
              <template v-slot:cell(sum_liabilities)="row">
                {{ row.value | formatAmount(currentCOA.locale, currentCOA.currency, currentCOA.currency_iso, true, 0) }}
              </template>
              <template v-slot:cell(total_liability_percentage)="row">
                {{ row.value | formatAmount(currentCOA.locale, currentCOA.currency, currentCOA.currency_iso, false, 1) }} %
              </template>
              <template v-slot:cell(result)="row">
                {{ row.value | formatAmount(currentCOA.locale, currentCOA.currency, currentCOA.currency_iso, true, 0) }}
              </template>
              <template v-slot:cell(total_result_percentage)="row">
                {{ row.value | formatAmount(currentCOA.locale, currentCOA.currency, currentCOA.currency_iso, false, 1) }} %
              </template>
              <template v-slot:cell(goal_amount)="row">
                {{ row.value | formatAmount(currentCOA.locale, currentCOA.currency, currentCOA.currency_iso, true, 0) }}
              </template>
              <template v-slot:cell(fulfillment)="row">
                {{ row.value | formatAmount(currentCOA.locale, currentCOA.currency, currentCOA.currency_iso, false, 1) }} %
              </template>

              <!-- FOOT -->
              <template v-slot:foot(bucket)>
                {{ $t('common.sum')}}
              </template>
              <template v-if="loaded && apiData && apiData.length > 0" v-slot:foot(fulfillment)>
                {{ apiData.totals.goal_fulfilment | formatAmount(currentCOA.locale, currentCOA.currency, currentCOA.currency_iso, false, 1) }} %
              </template>

              <template v-for="(field) in ['total_result_percentage','total_liability_percentage','total_asset_percentage']" :slot='"foot("+field+")"'>
                100 %
              </template>
              <template v-if="loaded && apiData && apiData.length > 0">
                <template v-for="(field) in ['amount','sum_liabilities','sum_assets','result','goal_amount']" :slot='"foot("+field+")"'>
                  {{ apiData.totals[field] | formatAmount(currentCOA.locale, currentCOA.currency, currentCOA.currency_iso, true, 0) }}
                </template>
              </template>

            </b-table>
          </b-col>
        </b-card>

      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { mapState } from 'vuex'
import formatAmount from '@/assets/filters/formatAmount'
import Loader from '@/components/common/Loader'

export default {
  name: 'FourBucketsTableSummary',
  props: ['api-data', 'loaded'],
  filters: { formatAmount },
  components: { Loader },
  data () {
    return {
      translationPath: 'reports.balance.four_buckets_report.submenu.',
      translationPathHeader: 'reports.balance.four_buckets_report.',
      translationPathTable: 'reports.balance.four_buckets_report.table.',
      conservation: 0,
      moderate: 0,
      aggressive: 0,
      play: 0,
      busy: true,
      rows: []
    }
  },
  computed: {
    ...mapState('user', ['currentCOA']),
    fieldValue () {
      return [
        { label: this.$t('common.bucket'), key: 'bucket', sortable: false },
        { label: this.$t('common.assets'), key: 'sum_assets', sortable: false, class: 'text-left text-md-right' },
        { label: this.$t('common.assets') + ' (%)', key: 'total_asset_percentage', sortable: false, class: 'text-left text-md-right td-asset-percentage' },
        { label: this.$t('common.liabilities'), key: 'sum_liabilities', sortable: false, class: 'text-left text-md-right' },
        { label: this.$t('common.liabilities') + ' (%)', key: 'total_liability_percentage', sortable: false, class: 'text-left text-md-right td-liability-percentage' },
        { label: this.$t(this.translationPathTable + 'result'), key: 'result', sortable: false, class: 'text-left text-md-right' },
        { label: this.$t(this.translationPathTable + 'result') + ' (%)', key: 'total_result_percentage', sortable: false, class: 'text-left text-md-right td-result-percentage' },
        { label: this.$t('common.goal_amount'), key: 'goal_amount', sortable: false, class: 'text-left text-md-right' },
        { label: this.$t('common.fulfilment'), key: 'fulfillment', sortable: false, class: 'text-left text-md-right' }
      ]
    }
  },
  methods: {
    fillTable () {
      if (!this.apiData || this.apiData === null || this.loaded === false || !this.currentCOA.locale) {
        this.busy = false
        return false
      }

      this.busy = true
      this.rows = []
      if (this.apiData.length === 0) {
        this.busy = false
        return false
      }

      const buckets = ['conservative', 'moderate', 'aggressive', 'speculation']

      buckets.map((bucket) => {
        this.rows.push({
          bucket: bucket,
          sum_assets: this.apiData.bucket_totals[bucket].sum_assets,
          total_asset_percentage: this.apiData.bucket_totals[bucket].total_asset_percentage,
          sum_liabilities: this.apiData.bucket_totals[bucket].sum_liabilities,
          total_liability_percentage: this.apiData.bucket_totals[bucket].total_liability_percentage,
          result: this.apiData.bucket_totals[bucket].result,
          total_result_percentage: this.apiData.bucket_totals[bucket].total_result_percentage,
          goal_amount: this.apiData.bucket_totals[bucket].goal_amount,
          fulfillment: this.apiData.bucket_totals[bucket].goal_fulfillment_percentage
        })
      })

      this.busy = false
    }
  },
  watch: {
    currentCOA: {
      deep: true,
      handler () {
        this.fillTable()
      }
    },
    apiData: {
      deep: true,
      handler () {
        this.fillTable()
      }
    }
  },
  created () {
    this.fillTable()
  }
}
</script>

<style lang="scss">
  .four-buckets-summary-table
  {
    thead tr th{
      border-top:0;
    }
    tfoot tr th{
      border-bottom: 2px solid black;
      border-top: 1px solid black;
    }

    td.td-asset-percentage, tfoot th.td-asset-percentage,
    td.td-liability-percentage, tfoot th.td-liability-percentage,
    td.td-result-percentage, tfoot th.td-result-percentage
    {
      padding-right: 15px;
      border-right:1px dashed #000;
    }
  }
</style>
