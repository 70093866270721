<template>
  <b-container fluid class="asset-allocation-report-table px-0">
    <b-row no-gutters>
      <b-col>
        <b-card class="main-gradient-content-card borderlight pt-3 mx-xl-auto">
          <template v-slot:header>
            <h2>{{ $t( translationPathHeader + 'submenu.settings')}} </h2>
          </template>

          <b-col class="col-md-8">
            <i18n :path="translationPathHeader + 'description_settings'" tag="p" class="text-gray">
              <b-link slot="link" href="javascript:void(0);" :data-beacon-article-sidebar="helpLink">{{ $t('common.read_in_manual') }}</b-link>
            </i18n>
            <i18n :path="translationPath + 'setting_intro'" tag="p" class="text-gray">
              <b-link slot="link" href="javascript:void(0);" :data-beacon-article-sidebar="helpLink">{{ $t('common.read_in_manual') }}</b-link>
            </i18n>
          </b-col>

          <b-col class="px-0">
            <form @submit.prevent="handleBucketSettings">
              <b-form-group
                  label-for="conservative_bucket_goal_size"
                  :label="$t('reports.balance.four_buckets_report.submenu.conservative')"
                  label-cols-lg="4"
                  class="align-items-center"
              >
                <currency-input
                  ref="CurrencyInputConservative"
                  v-model="conservative_bucket_goal_size"
                  :input-class="{'is-invalid': conservativeState === false, 'is-valid': conservativeState === true}"
                  :precision="0"
                />
              </b-form-group>
              <b-form-group
                  label-for="moderate_bucket_goal_size"
                  :label="$t('reports.balance.four_buckets_report.submenu.moderate')"
                  label-cols-lg="4"
                  class="align-items-center"
              >
                <currency-input
                  ref="CurrencyInputModerate"
                  v-model="moderate_bucket_goal_size"
                  :input-class="{'is-invalid': moderateState === false, 'is-valid': moderateState === true}"
                  :precision="0"
                />
              </b-form-group>
              <b-form-group
                  label-for="aggressive_bucket_goal_size"
                  :label="$t('reports.balance.four_buckets_report.submenu.aggressive')"
                  label-cols-lg="4"
                  class="align-items-center"
              >
                <currency-input
                  ref="CurrencyInputAggressive"
                  v-model="aggressive_bucket_goal_size"
                  :input-class="{'is-invalid': aggressiveState === false, 'is-valid': aggressiveState === true}"
                  :precision="0"
                />
              </b-form-group>
              <b-form-group
                  label-for="speculation_bucket_goal_size"
                  :label="$t('reports.balance.four_buckets_report.submenu.speculation')"
                  label-cols-lg="4"
                  class="align-items-center"
              >
                <currency-input
                  ref="CurrencyInputSpeculation"
                  v-model="speculation_bucket_goal_size"
                  :input-class="{'is-invalid': speculationState === false, 'is-valid': speculationState === true}"
                  :precision="0"
                />
              </b-form-group>
              <b-row class="px-3 mt-4">
                <b-col class="text-right">
                  <b-button type="submit" variant="primary">{{ $t('common.save') }}</b-button>
                </b-col>
              </b-row>
            </form>
          </b-col>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import axios from 'axios'
import CurrencyInput from '@/components/common/CurrencyInput'
import useComponentCurrencyInputMixin from '@/mixins/useComponentCurrencyInput'
import { mapState } from 'vuex'
export default {
  name: 'FourBucketsGoalAmounts',
  mixins: [useComponentCurrencyInputMixin],
  components: { CurrencyInput },
  data () {
    return {
      translationPath: 'reports.balance.four_buckets_report.',
      translationPathHeader: 'reports.balance.four_buckets_report.',
      conservative_bucket_goal_size: 0,
      moderate_bucket_goal_size: 0,
      aggressive_bucket_goal_size: 0,
      speculation_bucket_goal_size: 0
    }
  },
  computed: {
    ...mapState('user', ['currentUser', 'currentCOA']),
    helpLink () {
      switch (this.$i18n.locale) {
        case 'sv':
          return '5dd3efc104286364bc91e942'
        case 'da':
          return '5dd795a104286364bc921116'
        default:
          return '5dd792c504286364bc9210fe'
      }
    },
    conservativeState () {
      if (this.conservative_bucket_goal_size) {
        return true
      }
      return null
    },
    moderateState () {
      if (this.moderate_bucket_goal_size) {
        return true
      }
      return null
    },
    aggressiveState () {
      if (this.aggressive_bucket_goal_size) {
        return true
      }
      return null
    },
    speculationState () {
      if (this.speculation_bucket_goal_size) {
        return true
      }
      return null
    }
  },
  created () {
    const newVal = this.currentCOA || []
    this.loadSettings(newVal)
  },
  watch: {
    currentCOA: function (newVal, oldVal) {
      if (newVal) {
        this.loadSettings(newVal, oldVal)
      }
    }
  },
  methods: {
    loadSettings (newVal, oldVal = {}) {
      if (!Object.prototype.hasOwnProperty.call(newVal, 'id')) {
        return false
      }
      if (newVal.id === oldVal.id) {
        return false
      }

      if (Object.prototype.hasOwnProperty.call(newVal, 'options') && newVal.options.length > 0) {
        const conservativeIndex = this.findOptionsIndex(newVal.options, 'conservative_bucket_goal_size')
        const moderateIndex = this.findOptionsIndex(newVal.options, 'moderate_bucket_goal_size')
        const aggressiveIndex = this.findOptionsIndex(newVal.options, 'aggressive_bucket_goal_size')
        const speculationIndex = this.findOptionsIndex(newVal.options, 'speculation_bucket_goal_size')
        if (conservativeIndex > -1) {
          this.conservative_bucket_goal_size = Number(newVal.options[conservativeIndex].value)
        }
        if (moderateIndex > -1) {
          this.moderate_bucket_goal_size = Number(newVal.options[moderateIndex].value)
        }
        if (aggressiveIndex > -1) {
          this.aggressive_bucket_goal_size = Number(newVal.options[aggressiveIndex].value)
        }
        if (speculationIndex > -1) {
          this.speculation_bucket_goal_size = Number(newVal.options[speculationIndex].value)
        }
        this.rerenderCurrencyInputs()
      } else {
        axios.get(`${process.env.VUE_APP_ROOT_API}/charts-of-accounts/options-set?options=speculation_bucket_goal_size,aggressive_bucket_goal_size,moderate_bucket_goal_size,conservative_bucket_goal_size`)
          .then(response => {
            for (const prop in response.data.data) {
              this[prop] = response.data.data[prop]
            }
          })
          .catch(err => {
            console.error(err)
          })
          .finally(() => {
            this.rerenderCurrencyInputs()
          })
      }
    },
    findOptionsIndex (arr, search) {
      return arr.findIndex(item => {
        return item.name === search
      })
    },
    handleBucketSettings () {
      const fourBucketSettinsData = {
        conservative_bucket_goal_size: this.conservative_bucket_goal_size,
        moderate_bucket_goal_size: this.moderate_bucket_goal_size,
        aggressive_bucket_goal_size: this.aggressive_bucket_goal_size,
        speculation_bucket_goal_size: this.speculation_bucket_goal_size
      }
      axios.put(`${process.env.VUE_APP_ROOT_API}/charts-of-accounts/options`, { options: fourBucketSettinsData })
        .then(data => {
          this.$store.dispatch('user/loadCurrentCOA', null, { root: true })
          this.$bvToast.toast(this.$t(this.translationPath + 'toast.success_message'), {
            title: this.$t(this.translationPath + 'toast.heading'),
            variant: 'success',
            solid: true,
            'auto-hide-delay': 3000
          })
          this.$emit('data-updated')
        })
        .catch(err => {
          console.error(err)
          this.$bvToast.toast(this.$t(this.translationPath + 'toast.error_message'), {
            title: this.$t(this.translationPath + 'toast.heading'),
            variant: 'danger',
            solid: true,
            'auto-hide-delay': 3000
          })
        })
    }
  }
}
</script>

<style lang="scss">
.bucketSettings {
    button {
        padding: 8px 35px;
    }
}
</style>
