<template>
  <b-container fluid class="four-buckets-report-chart-wrapper" :class="{ 'with-data': chartData ? true : false }">
    <template v-if="apiData && !Array.isArray(this.apiData) && Object.keys(apiData).length > 0">
      <treemap-nested
        v-if="currentCOA.currency"
        :data="chartData"
        :currencySymbol="currentCOA.currency ? currentCOA.currency : ''"
      />
    </template>
    <template v-else>
      <empty-graph :messsage="$t(translationPath + 'no_data_in_chart')"/>
    </template>
  </b-container>
</template>

<script>
import EmptyGraph from '@/components/common/EmptyGraph'
import { mapState } from 'vuex'
import treemapNested from '@/components/d3charts/treemap-nested/treemap-nested.vue'
import chroma from 'chroma-js'

export default {
  name: 'FourBucketsChartBucket',
  props: ['bucket', 'api-data'],
  components: { treemapNested, EmptyGraph },
  data () {
    return {
      translationPath: 'reports.balance.four_buckets_report.',
      chartData: null,
      // colors: ['#FEC600', '#ff7c43', '#f95d6a', '#d45087'],
      // textColors: ['#000', '#fff', '#000', '#fff'],
      baseColors: {
        conservative: { backgroundFrom: '#FEC600', backgroundTo: '#FEC600', text: '#000' },
        moderate: { backgroundFrom: '#ff7c43', backgroundTo: '#ff7c43', text: '#fff' },
        aggressive: { backgroundFrom: '#f95d6a', backgroundTo: '#f95d6a', text: '#fff' },
        speculation: { backgroundFrom: '#d45087', backgroundTo: '#d45087', text: '#fff' }
      }
    }
  },
  computed: {
    ...mapState('user', ['currentCOA'])
  },
  methods: {
    async fillChart () {
      this.chartData = null
      let iSum = 0
      let aTmpChildren = []
      if (!this.currentCOA.locale || !this.bucket || this.apiData === null || this.apiData.length === 0) {
        return false
      }
      if (!this.apiData.bucket_totals[this.bucket].sum_assets && !this.apiData.bucket_totals[this.bucket].sum_liabilities) {
        return false
      }

      const accountsAssetsFree = this.apiData.bucket_accounts[this.bucket].filter((item) => item.type === 'asset' && item.is_bucket_free_capital)
      const accountsAssetsTied = this.apiData.bucket_accounts[this.bucket].filter((item) => item.type === 'asset' && !item.is_bucket_free_capital)
      const accountsLiabilitiesFree = this.apiData.bucket_accounts[this.bucket].filter((item) => item.type === 'liability' && item.is_bucket_free_capital)
      const accountsLiabilitiesTied = this.apiData.bucket_accounts[this.bucket].filter((item) => item.type === 'liability' && !item.is_bucket_free_capital)
      let maxColors = Math.max(accountsAssetsFree.length, accountsAssetsTied.length, accountsLiabilitiesFree.length, accountsLiabilitiesTied.length)
      maxColors = maxColors >= 10 ? maxColors : 10

      const colors = chroma.scale([chroma(this.baseColors[this.bucket].backgroundFrom).brighten(0).hex(), chroma(this.baseColors[this.bucket].backgroundTo).brighten(0).hex()]).gamma(3).correctLightness().colors(maxColors)

      this.chartData = null

      const data = [
        {
          label: this.$t(this.translationPath + 'table.asset'),
          color: colors[0],
          textColor: this.baseColors[this.bucket].text,
          children: [
            {
              label: this.$t(this.translationPath + 'table.free_capital'),
              color: colors[0],
              textColor: this.baseColors[this.bucket].text,
              children: []
            },
            {
              label: this.$t(this.translationPath + 'table.tied_capital'),
              color: colors[0],
              textColor: this.baseColors[this.bucket].text,
              children: []
            }
          ]
        },
        {
          label: this.$t(this.translationPath + 'table.liability'),
          color: chroma(colors[0]).brighten(0.75),
          textColor: chroma(this.baseColors[this.bucket].text).darken(10),
          children: [
            {
              label: this.$t(this.translationPath + 'table.free_capital'),
              color: chroma(colors[0]).brighten(0.75),
              textColor: chroma(this.baseColors[this.bucket].text).darken(10),
              children: []
            },
            {
              label: this.$t(this.translationPath + 'table.tied_capital'),
              color: chroma(colors[0]).brighten(0.75),
              textColor: chroma(this.baseColors[this.bucket].text).darken(10),
              children: []
            }
          ]
        }
      ]

      iSum = 0
      aTmpChildren = []
      accountsAssetsFree.map((account, index) => {
        if (account.balance !== 0) {
          aTmpChildren.push({
            label: account.title,
            color: colors[index],
            textColors: this.baseColors[this.bucket].text,
            amount: account.balance
          })
        }
        iSum += account.balance
      })
      if (iSum !== 0) { data[0].children[0].children = aTmpChildren }

      iSum = 0
      aTmpChildren = []
      accountsAssetsTied.map((account, index) => {
        if (account.balance !== 0) {
          aTmpChildren.push({
            label: account.title,
            color: colors[index],
            textColors: this.baseColors[this.bucket].text,
            amount: account.balance
          })
        }
        iSum += account.balance
      })
      if (iSum !== 0) { data[0].children[1].children = aTmpChildren }

      iSum = 0
      aTmpChildren = []
      accountsLiabilitiesFree.map((account, index) => {
        if (account.balance !== 0) {
          aTmpChildren.push({
            label: account.title,
            color: chroma(colors[index]).brighten(1),
            textColors: this.baseColors[this.bucket].text,
            amount: account.balance
          })
        }
        iSum += account.balance
      })
      if (iSum !== 0) { data[1].children[0].children = aTmpChildren }

      iSum = 0
      aTmpChildren = []
      accountsLiabilitiesTied.map((account, index) => {
        if (account.balance !== 0) {
          aTmpChildren.push({
            label: account.title,
            color: chroma(colors[index]).brighten(1),
            textColors: this.baseColors[this.bucket].text,
            amount: account.balance
          })
        }
        iSum += account.balance
      })
      if (iSum !== 0) { data[1].children[1].children = aTmpChildren }

      this.chartData = data
    }
  },
  watch: {
    currentCOA: {
      deep: true,
      handler () {
        this.fillChart()
      }
    },
    bucket () {
      this.fillChart()
    }
  },
  created () {
    this.fillChart()
  }
}
</script>

<style lang="scss">
.four-buckets-report-chart-wrapper {
  min-height: 250px;
}
.four-buckets-report-chart-wrapper.with-data {
  min-height: 700px;
}
</style>
