<template>
  <div class="four-buckets-report" :class="'tab-' + activeTab">
    <b-container class="main-gradient light" fluid>
      <b-container class="main-gradient-header-wrapper mb-4"> <!-- Breadcrumbs, tabs, title -->
        <b-row class="breadcrumb-wrapper" no-gutters>
          <b-col no-gutters>
            <b-breadcrumb :items="breadcrumbItems" class="breadcrumb-light"></b-breadcrumb>
            <h1 class="text-left">{{ $t(translationPath + 'title') }}</h1>
          </b-col>
        </b-row>

        <b-row class="main-gradient-tab-menu-wrapper" no-gutters>
          <b-col no-gutters>
            <four-buckets-submenu ref="Tabmenu" :activeTab="activeTab" @tab-switched="onTabSwitched"></four-buckets-submenu>
          </b-col>
        </b-row>
      </b-container>

      <b-row class="main-gradient-chart-wrapper"> <!-- Charts -->
        <div class="w-100 pb-5">
          <four-buckets-chart-summary v-if="activeTab === 'summary'" :api-data="apiData"></four-buckets-chart-summary>
          <four-buckets-chart-summary-asset-allocation v-if="activeTab === 'summary-asset-allocation'" :api-data="apiData"></four-buckets-chart-summary-asset-allocation>
          <four-buckets-chart-bucket
            v-if="['aggressive', 'conservative', 'moderate', 'speculation'].indexOf(activeTab) !== -1"
            :bucket="activeTab"
            :api-data="apiData"
          ></four-buckets-chart-bucket>
        </div>
      </b-row>
    </b-container>

    <b-container class="main-content-wrapper pt-0"> <!-- Page content -->
      <four-buckets-table-summary v-if="activeTab === 'summary'" :loaded="loaded" :api-data="apiData"></four-buckets-table-summary>
      <four-buckets-table-summary-asset-allocation v-if="activeTab === 'summary-asset-allocation'" :api-data="apiData"></four-buckets-table-summary-asset-allocation>
      <four-buckets-table-bucket
        v-if="['conservative', 'moderate', 'aggressive', 'speculation'].indexOf(activeTab) !== -1"
        :api-data="apiData"
        :bucket="activeTab"
      ></four-buckets-table-bucket>
      <four-buckets-goal-amounts v-if="activeTab === 'settings'" @data-updated="loadData"></four-buckets-goal-amounts>
      <four-bucket-settings
        v-if="activeTab === 'settings'"
        :sections="sections"
        @sections-update="handleSectionUpdate"
        @settings-updated="handleSettingsUpdate"
      />
    </b-container>
  </div>
</template>

<script>
import axios from 'axios'
import FourBucketsSubmenu from './FourBucketsSubmenu'
import FourBucketsTableSummary from './FourBucketsTableSummary'
import FourBucketsTableBucket from './FourBucketsTableBucket'
import FourBucketsGoalAmounts from './FourBucketsGoalAmounts'
import FourBucketsChartSummary from './FourBucketsChartSummary'
import FourBucketsChartBucket from './FourBucketsChartBucket'
import FourBucketsChartSummaryAssetAllocation from './FourBucketsChartSummaryAssetAllocation'
import FourBucketsTableSummaryAssetAllocation from './FourBucketsTableSummaryAssetAllocation'
import FourBucketSettings from './FourBucketSettings'
import _ from 'lodash'
import titleMixins from '@/mixins/title'

export default {
  name: 'FourBuckets',
  mixins: [titleMixins],
  components: {
    FourBucketsSubmenu,
    FourBucketsTableSummary,
    FourBucketsTableBucket,
    FourBucketsGoalAmounts,
    FourBucketsChartSummary,
    FourBucketsChartBucket,
    FourBucketsChartSummaryAssetAllocation,
    FourBucketsTableSummaryAssetAllocation,
    FourBucketSettings
  },
  data () {
    return {
      activeTab: 'summary',
      apiData: null,
      loaded: false,
      translationPath: 'reports.balance.four_buckets_report.',
      sections: [
        {
          label: 'common.assets',
          description: 'common.settings.descriptions.asset',
          model: 'asset',
          value: [],
          child: [
            {
              type: 'select',
              multiple: true,
              options: []
            }
          ]
        },
        {
          label: 'common.liabilities',
          description: 'common.settings.descriptions.liability',
          model: 'liability',
          value: [],
          child: [
            {
              type: 'select',
              multiple: true,
              options: []
            }
          ]
        },
        {
          label: 'common.title',
          model: 'title',
          required: true,
          value: null,
          disableButtons: true,
          child: [
            {
              type: 'input'
            }
          ]
        },
        {
          label: 'common.advanced_filter.description',
          model: 'description',
          value: null,
          show: true,
          disableButtons: true,
          child: [
            {
              type: 'textarea'
            }
          ]
        }
      ]
    }
  },
  computed: {
    breadcrumbItems () {
      return [
        { text: this.$t('reports._common.reports_and_insights'), to: '/reports' },
        { text: this.$t('reports._common.balance_reports'), to: '/reports/balance' },
        { text: '', active: true }
      ]
    },
    titleMeta () {
      return this.$t(this.translationPath + 'title')
    }
  },
  methods: {
    handleSectionUpdate (value) {
      this.sections = value
    },
    handleSettingsUpdate (value) {
      this.handleSectionUpdate(value)
      this.loadData()
        .then(() => {
          this.$bvToast.toast(this.$t('common.filter_settings_description'), {
            title: this.$t('common.filter_settings_title'),
            variant: 'success',
            solid: true
          })
        })
    },
    onTabSwitched (tab) {
      this.activeTab = tab
      if (tab !== this.$route.params.tab) {
        this.$router.push({ params: { tab: tab } })
      }
    },
    changeTab (tab) {
      if (['summary', 'summary-asset-allocation', 'conservative', 'moderate', 'speculation', 'aggressive', 'settings'].indexOf(this.$route.params.tab) !== -1) {
        this.$refs.Tabmenu.changeTab(tab)
        this.setPageTitle(this.titleMeta)
      }
    },
    loadData () {
      const sectionsValue = _.flatten([...this.sections].filter((el) => el.model !== 'title' && el.model !== 'description').map((el) => el.value).filter((el) => el))
      const include = sectionsValue.length > 0 ? sectionsValue.join(',') : null
      let ApiEndpoint = `${process.env.VUE_APP_ROOT_API}/reports/balance/four-bucket`
      if (include) {
        ApiEndpoint += `?include=${include}`
      }
      this.loaded = false
      return axios.get(ApiEndpoint)
        .then((response) => {
          this.apiData = response.data.data
          this.loaded = true
          return true
        })
        .catch((error) => {
          console.error(error)
          return false
        })
    }
  },
  created () {
    this.activeTab = 'summary'
    this.loadData()
  },
  mounted () {
    this.changeTab(this.$route.params.tab)
  },
  watch: {
    '$route.params.tab' () {
      this.changeTab(this.$route.params.tab)
    },
    titleMeta: {
      handler () {
        this.setPageTitle(this.titleMeta)
      },
      immediate: true
    }
  }
}
</script>

<style lang="scss">
.four-buckets-report {
  width: 100%;
  table {
    a {
      color: #000;
    }
  }
}
</style>
