<template>
  <b-container fluid class="asset-allocation-report-table px-0">
    <b-row no-gutters>
      <b-col>

        <b-card class="main-gradient-content-card borderlight pt-3 mx-xl-auto mt-2">
          <template v-slot:header>
            <h2>{{ $t( translationPathTable + 'asset_allocation_summary_table_title')}} </h2>
          </template>

          <b-col class="col-md-8">
            <i18n :path="translationPathTable + 'asset_allocation_summary_table_percentages_description'" tag="p" class="text-gray">
            </i18n>
          </b-col>

          <b-col class="px-0 mb-5">
            <b-col class="px-0 mb-5">

              <b-table class="spirecta-simple-table four-buckets-table four-buckets-summary-table mt-4" show-empty hover responsive striped foot-clone
                       stacked="md"
                       :items="assetAllocationTableData"
                       :fields="assetAllocationTablePercentagesTable"
                       :busy="busy || !currentCOA.locale"
              >
                <template v-slot:cell(bucket)="row">
                  <b-link href="javscript:void(0);" @click="$emit('change-tab', row.value)">{{ $t(translationPath + row.value)}}</b-link>
                </template>
                <template v-for='(field) in assetAllocationTablePercentageCols' :slot='"cell("+field+")"' slot-scope='row'>
                  {{ row.value | formatAmount(currentCOA.locale, currentCOA.currency, currentCOA.currency_iso, false, 1) }} %
                </template>

                <template v-slot:foot(bucket)>
                  {{ $t(translationPath + 'percentage_of_total')}}
                </template>
                <template v-slot:foot(sum_percentage)>
                  100.0 %
                </template>
                <template v-if="apiData && Object.prototype.hasOwnProperty.call(apiData, 'totals')">
                  <template v-for="(field) in ['equities_percentage','fixed_income_percentage','cash_percentage','gold_percentage','alternatives_percentage','not_defined_percentage']" :slot='"foot("+field+")"'>
                    {{ apiData.totals.asset_allocation.percentages[field.replace('_percentage','')] | formatAmount(currentCOA.locale, currentCOA.currency, currentCOA.currency_iso, false, 1) }} %
                  </template>
                </template>

              </b-table>
            </b-col>
          </b-col>
        </b-card>

        <b-card class="main-gradient-content-card borderlight pt-3 mx-xl-auto mt-5">
          <template v-slot:header>
            <h2>{{ $t( translationPathTable + 'asset_allocation_summary_table_title')}} </h2>
          </template>

          <b-col class="col-md-8">
            <i18n :path="translationPathTable + 'asset_allocation_summary_table_amounts_description'" tag="p" class="text-gray">
            </i18n>
          </b-col>

          <b-col class="px-0 mb-5">
            <b-col class="px-0 mb-5">
              <b-table class="spirecta-simple-table four-buckets-table four-buckets-summary-table" show-empty hover responsive striped foot-clone
                       stacked="md"
                       :items="assetAllocationTableData"
                       :fields="assetAllocationTable"
                       :busy="busy || !currentCOA.locale"
                       :empty-text="$t(translationPathTable + 'empty_text')"
              >
                <template v-slot:cell(bucket)="row">
                  <b-link href="javscript:void(0);" @click="$emit('change-tab', row.value)">{{ $t(translationPath + row.value)}}</b-link>
                </template>
                <template v-for='(field) in assetAllocationTableCols' :slot='"cell("+field+")"' slot-scope='row'>
                  {{ row.value | formatAmount(currentCOA.locale, currentCOA.currency, currentCOA.currency_iso, true, 0) }}
                </template>

                <template v-slot:foot(bucket)>
                  {{ $t('reports.balance.four_buckets_report.table.total_asset_class_amounts')}}
                </template>
                <template v-if="apiData && Object.prototype.hasOwnProperty.call(apiData, 'totals')" v-slot:foot(sum_assets)>
                  {{ apiData.totals.sum_assets | formatAmount(currentCOA.locale, currentCOA.currency, currentCOA.currency_iso, true, 0) }}
                </template>
                <template v-if="apiData && Object.prototype.hasOwnProperty.call(apiData, 'totals')">
                  <template v-for="(field) in ['equities','fixed_income','cash','gold','alternatives','other','not_defined']" :slot='"foot("+field+")"'>
                    {{ apiData.totals.asset_allocation.sums[field] | formatAmount(currentCOA.locale, currentCOA.currency, currentCOA.currency_iso, true, 0) }}
                  </template>
                </template>
              </b-table>

            </b-col>
          </b-col>
        </b-card>

      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { mapState } from 'vuex'
import formatAmount from '@/assets/filters/formatAmount'

export default {
  name: 'FourBucketsTableSummaryAssetAllocation',
  props: ['api-data'],
  filters: { formatAmount },
  data () {
    return {
      translationPath: 'reports.balance.four_buckets_report.submenu.',
      translationPathHeader: 'reports.balance.four_buckets_report.',
      translationPathTable: 'reports.balance.four_buckets_report.table.',
      conservation: 0,
      moderate: 0,
      aggressive: 0,
      play: 0,
      busy: true,
      assetAllocationTableData: [],
      assetAllocationTablePercentages: [],
      assetAllocationTableCols: ['equities', 'fixed_income', 'cash', 'gold', 'alternatives', 'other', 'not_defined', 'sum_assets'],
      assetAllocationTablePercentageCols: ['equities_percentage', 'fixed_income_percentage', 'cash_percentage', 'gold_percentage', 'alternatives_percentage', 'other_percentage', 'not_defined_percentage', 'sum_percentage']
    }
  },
  computed: {
    ...mapState('user', ['currentCOA']),
    assetAllocationTable () {
      return [
        { label: this.$t('common.bucket'), key: 'bucket', sortable: false },
        { label: this.$t('reports.balance.asset_allocation_report.common.equities'), key: 'equities', sortable: false, class: 'text-left text-md-right' },
        { label: this.$t('reports.balance.asset_allocation_report.common.fixed_income'), key: 'fixed_income', sortable: false, class: 'text-left text-md-right' },
        { label: this.$t('reports.balance.asset_allocation_report.common.cash'), key: 'cash', sortable: false, class: 'text-left text-md-right' },
        { label: this.$t('reports.balance.asset_allocation_report.common.gold'), key: 'gold', sortable: false, class: 'text-left text-md-right' },
        { label: this.$t('reports.balance.asset_allocation_report.common.alternatives'), key: 'alternatives', sortable: false, class: 'text-left text-md-right' },
        { label: this.$t('reports.balance.asset_allocation_report.common.other'), key: 'other', sortable: false, class: 'text-left text-md-right' },
        { label: this.$t('reports.balance.asset_allocation_report.common.not_defined'), key: 'not_defined', sortable: false, class: 'text-left text-md-right td-border-right' },
        { label: this.$t(this.translationPathTable + 'sum_assets'), key: 'sum_assets', sortable: false, class: 'text-left text-md-right' }
      ]
    },
    assetAllocationTablePercentagesTable () {
      return [
        { label: this.$t('common.bucket'), key: 'bucket', sortable: false },
        { label: this.$t('reports.balance.asset_allocation_report.common.equities'), key: 'equities_percentage', sortable: false, class: 'text-left text-md-right' },
        { label: this.$t('reports.balance.asset_allocation_report.common.fixed_income'), key: 'fixed_income_percentage', sortable: false, class: 'text-left text-md-right' },
        { label: this.$t('reports.balance.asset_allocation_report.common.cash'), key: 'cash_percentage', sortable: false, class: 'text-left text-md-right' },
        { label: this.$t('reports.balance.asset_allocation_report.common.gold'), key: 'gold_percentage', sortable: false, class: 'text-left text-md-right' },
        { label: this.$t('reports.balance.asset_allocation_report.common.alternatives'), key: 'alternatives_percentage', sortable: false, class: 'text-left text-md-right' },
        { label: this.$t('reports.balance.asset_allocation_report.common.other'), key: 'other_percentage', sortable: false, class: 'text-left text-md-right' },
        { label: this.$t('reports.balance.asset_allocation_report.common.not_defined'), key: 'not_defined_percentage', sortable: false, class: 'text-left text-md-right td-border-right' },
        { label: this.$t('common.sum'), key: 'sum_percentage', sortable: false, class: 'text-left text-md-right' }
      ]
    },
    helpLink () {
      switch (this.$i18n.locale) {
        case 'sv':
          return '5dd3efc104286364bc91e942'
        case 'da':
          return '5dd795a104286364bc921116'
        default:
          return '5e28ccaa2c7d3a7e9ae69ef8'
      }
    }
  },
  methods: {
    fillTable () {
      if (!this.apiData || this.apiData === null || !this.currentCOA.locale) {
        this.busy = false
        return false
      }
      if (this.apiData.length === 0) {
        this.busy = false
        return false
      }
      this.busy = true
      const buckets = ['conservative', 'moderate', 'aggressive', 'speculation']

      buckets.map((bucket) => {
        this.assetAllocationTableData.push({
          bucket: bucket,
          sum_assets: this.apiData.bucket_totals[bucket].sum_assets,
          equities: this.apiData.bucket_totals[bucket].asset_allocation.sums.equities,
          fixed_income: this.apiData.bucket_totals[bucket].asset_allocation.sums.fixed_income,
          cash: this.apiData.bucket_totals[bucket].asset_allocation.sums.cash,
          gold: this.apiData.bucket_totals[bucket].asset_allocation.sums.gold,
          alternatives: this.apiData.bucket_totals[bucket].asset_allocation.sums.alternatives,
          other: this.apiData.bucket_totals[bucket].asset_allocation.sums.other,
          not_defined: this.apiData.bucket_totals[bucket].asset_allocation.sums.not_defined,
          equities_percentage: this.apiData.bucket_totals[bucket].asset_allocation.percentages.equities,
          fixed_income_percentage: this.apiData.bucket_totals[bucket].asset_allocation.percentages.fixed_income,
          cash_percentage: this.apiData.bucket_totals[bucket].asset_allocation.percentages.cash,
          gold_percentage: this.apiData.bucket_totals[bucket].asset_allocation.percentages.gold,
          alternatives_percentage: this.apiData.bucket_totals[bucket].asset_allocation.percentages.alternatives,
          other_percentage: this.apiData.bucket_totals[bucket].asset_allocation.percentages.other,
          not_defined_percentage: this.apiData.bucket_totals[bucket].asset_allocation.percentages.not_defined,
          sum_percentage: 100
        })
      })

      this.busy = false
    }
  },
  watch: {
    currentCOA: {
      deep: true,
      handler () {
        this.fillTable()
      }
    },
    apiData: {
      deep: true,
      handler () {
        this.fillTable()
      }
    }
  },
  created () {
    this.fillTable()
  }
}
</script>

<style lang="scss">
  .four-buckets-summary-table
  {
    thead tr th{
      border-top:0;
    }
    tfoot tr th{
      border-bottom: 2px solid black;
      border-top: 1px solid black;
    }

    td.td-border-right, tfoot th.td-border-right
    {
      padding-right: 15px;
      border-right:1px dashed #000;
    }
  }
</style>
