<template>
  <b-container fluid class="four-buckets-report-chart-wrapper">
    <template v-if="apiData && !Array.isArray(this.apiData) && Object.keys(apiData).length > 0">
      <treemap-nested v-if="currentCOA.currency" :data="chartData" :currencySymbol="currentCOA.currency" />
    </template>
    <template v-else>
      <empty-graph :messsage="$t(translationPath + 'no_data_in_chart')"/>
    </template>
  </b-container>
</template>

<script>
import { mapState } from 'vuex'
import treemapNested from '@/components/d3charts/treemap-nested/treemap-nested.vue'
import EmptyGraph from '@/components/common/EmptyGraph'

export default {
  name: 'FourBucketsChartSummary',
  props: ['api-data'],
  components: { treemapNested, EmptyGraph },
  data () {
    return {
      chartData: null,
      colors: ['#FEC600', '#ff7c43', '#f95d6a', '#d45087'],
      textColors: ['#000', '#fff', '#fff', '#fff'],
      translationPath: 'reports.balance.four_buckets_report.'
    }
  },
  computed: {
    ...mapState('user', ['currentCOA'])
  },
  methods: {
    async fillChart () {
      if (this.apiData === null || this.apiData.length === 0) {
        return false
      }
      const buckets = ['conservative', 'moderate', 'aggressive', 'speculation']
      const data = []

      buckets.map((bucket, index) => {
        data.push({
          label: this.$t(this.translationPath + 'submenu.' + bucket),
          color: this.colors[index],
          textColor: this.textColors[index],
          amount: this.apiData.bucket_totals[bucket].result,
          percent: this.apiData.bucket_totals[bucket].total_result_percentage
        })
      })

      this.chartData = data
    }
  },
  watch: {
    currentCOA: {
      deep: true,
      handler () {
        this.fillChart()
      }
    },
    apiData: {
      deep: true,
      handler () {
        this.fillChart()
      }
    }
  },
  created () {
    this.fillChart()
  }
}
</script>
