<template>
  <div class="main-gradient-header-tab-menu">
    <b-row class="submenu-container dark justify-content-center" no-gutters>
      <b-col md="auto">
        <b-link class="main-gradient-header-tab-menu-tab-item" tab="summary" :class="{selected: activeTab === 'summary'}"  @click="onTabClick">
          {{ $t(translationPath + 'summary') }}
        </b-link>
        <span class="main-gradient-header-tab-menu-divider mx-3">|</span>
      </b-col>
      <b-col md="auto">
        <b-link class="main-gradient-header-tab-menu-tab-item" tab="summary-asset-allocation" :class="{selected: activeTab === 'summary-asset-allocation'}"  @click="onTabClick">
          {{ $t(translationPath + 'summary_asset_allocation') }}
        </b-link>
        <span class="main-gradient-header-tab-menu-divider mx-3">|</span>
      </b-col>
      <b-col md="auto">
        <b-link class="main-gradient-header-tab-menu-tab-item" tab="conservative" :class="{selected: activeTab === 'conservative'}"  @click="onTabClick">
          {{ $t(translationPath + 'conservative') }}
        </b-link>
        <span class="main-gradient-header-tab-menu-divider mx-3">|</span>
      </b-col>
      <b-col md="auto">
        <b-link class="main-gradient-header-tab-menu-tab-item" tab="moderate" :class="{selected: activeTab === 'moderate'}"  @click="onTabClick">
          {{ $t(translationPath + 'moderate') }}
        </b-link>
        <span class="main-gradient-header-tab-menu-divider mx-3">|</span>
      </b-col>
      <b-col md="auto">
        <b-link class="main-gradient-header-tab-menu-tab-item" tab="aggressive" :class="{selected: activeTab === 'aggressive'}"  @click="onTabClick">
          {{ $t(translationPath + 'aggressive') }}
        </b-link>
        <span class="main-gradient-header-tab-menu-divider mx-3">|</span>
      </b-col>
      <b-col md="auto">
        <b-link class="main-gradient-header-tab-menu-tab-item" tab="speculation" :class="{selected: activeTab === 'speculation'}"  @click="onTabClick">
          {{ $t(translationPath + 'speculation') }}
        </b-link>
        <span class="main-gradient-header-tab-menu-divider mx-3">|</span>
      </b-col>
      <b-col md="auto">
        <b-link class="main-gradient-header-tab-menu-tab-item" tab="settings" :class="{selected: activeTab === 'settings'}"  @click="onTabClick">
          {{ $t(translationPath + 'settings') }}
        </b-link>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: 'FourBucketsSubmenu',
  props: {
    activeTab: {
      type: String,
      default: 'total'
    }
  },
  data () {
    return {
      translationPath: 'reports.balance.four_buckets_report.submenu.'
    }
  },
  methods: {
    onTabClick (event) {
      this.$emit('tab-switched', event.target.attributes.tab.value)
    },
    changeTab (tab) {
      this.$emit('tab-switched', tab)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/submenu.scss';
</style>
