<template>
  <b-container fluid class="asset-allocation-report-table px-0">
    <b-row no-gutters>
      <b-col>
        <b-card class="main-gradient-content-card borderlight pt-3 mx-xl-auto">
          <template v-slot:header>
            <h2>{{ $t( translationPath + 'submenu.' + bucket)}} </h2>
          </template>

          <b-col class="col-md-8 pl-3">
            <i18n :path="translationPath + 'description_' + bucket" tag="p" class="text-gray">
              <b-link slot="link" href="https://forum.spirecta.se/t/fyra-hinkar-principen-en-fordjupning/68" target="_blank">{{ $t('common.read_in_manual') }}</b-link>
            </i18n>
          </b-col>

          <b-col>
            <b-table class="spirecta-simple-table four-buckets-table mb-5" show-empty hover responsive striped
                stacked="md"
                :tbodyTrClass="trClass"
                :items="rows"
                :fields="fields"
                :busy="busy"
                :empty-text="$t(translationPath + 'table.empty_text')"
            >
                <!--loader-->
                <template v-slot:table-busy>
                  <loader/>
                </template>
                <!--loader ends here-->
                <template v-slot:cell(title)="row">
                    <template v-if="row.item.belongs_to === 'empty'">
                        &nbsp;
                    </template>
                    <template v-else-if="row.item.belongs_to === 'difference'">
                      {{ $t(translationPath + 'table.difference_' + bucket) }}
                    </template>
                    <template v-else-if="row.item.belongs_to === 'total'">
                      <i18n tag="span" :path="translationPath + 'total_asset_liability_in_bucket'">
                        <span slot="bucket">{{$t( translationPath + 'submenu.'+ bucket)}}</span>
                        <span slot="asset_class" style="text-transform: lowercase">{{$t('common.'+row.item.type+'s')}}</span>
                      </i18n>
                    </template>
                    <template v-else-if="row.item.belongs_to === 'capital_total'">
                      <span>{{ $t('common.subtotal') }}</span>
                    </template>
                    <template v-else-if="row.item.belongs_to === 'grouptitle'">
                      {{ $t(translationPath + 'table.' + row.value) }}
                    </template>
                    <template v-else-if="row.item.belongs_to === 'capital_title'">
                      <span>{{$t(translationPath + 'table.' + row.value)}}</span>
                    </template>
                    <template v-else-if="row.item.belongs_to === 'no_record'">
                      <span class="font-weight-normal">{{$t(translationPath + 'table.no_' + row.item.type)}}</span>
                    </template>
                    <template v-else>
                      <span class="px-2 account_title">&ndash;</span>
                      <span class="font-weight-normal"><b-link :to="'/reports/performance/accounts/' + row.item.id + '/view'">{{ row.value }}</b-link></span>
                    </template>
                </template>

                <template v-slot:cell(free_capital)="row">
                  <template v-if="!row.item.belongs_to && row.item.is_bucket_free_capital">
                    <span :class="'amount_'+row.item.type">{{ row.item.amount | formatAmount(currentCOA.locale, currentCOA.currency, currentCOA.currency_iso) }}</span>
                  </template>
                  <template v-else-if="row.item.belongs_to === 'total' || row.item.belongs_to === 'difference'">
                    {{ row.item.amountFreeCapital | formatAmount(currentCOA.locale, currentCOA.currency, currentCOA.currency_iso) }}
                  </template>
                  <template v-else>&nbsp;</template>
                </template>

                <!--Amount-->
                <template v-slot:cell(amount)="row">
                  <template v-if="row.item.belongs_to === 'empty'">
                      &nbsp;
                  </template>
                  <template v-else-if="row.item.belongs_to === 'grouptitle'">
                      &nbsp;
                  </template>
                  <template v-else-if="row.item.belongs_to === 'capital_title'">
                      &nbsp;
                  </template>
                  <template v-else-if="row.item.belongs_to === 'no_record'">
                      &nbsp;
                  </template>
                  <template v-else>
                    <span :class="'amount_'+row.item.type">{{ row.value | formatAmount(currentCOA.locale, currentCOA.currency, currentCOA.currency_iso, true, 0) }}</span>
                  </template>
                </template>
            </b-table>
          </b-col>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { mapState } from 'vuex'
import formatAmount from '@/assets/filters/formatAmount'
import Loader from '@/components/common/Loader'

export default {
  name: 'FourBucketsTableBucket',
  props: ['api-data', 'bucket'],
  filters: { formatAmount },
  components: {
    Loader
  },
  data () {
    return {
      translationPath: 'reports.balance.four_buckets_report.',
      rows: [],
      busy: true
    }
  },
  computed: {
    ...mapState('user', ['currentCOA']),
    fields () {
      return [
        { key: 'title', label: this.$t(this.translationPath + 'table.title_' + (this.type ? this.type : 'conservative')), sortable: false },
        { key: 'amount', label: '', sortable: false, class: 'text-center text-md-right' }
      ]
    }
  },
  methods: {
    fillTable () {
      if (!this.apiData || this.apiData === null || !this.currentCOA.locale) {
        this.busy = false
        return false
      }

      this.busy = true
      this.rows = []
      if (this.apiData.length === 0) {
        this.busy = false
        return false
      }

      const accounts = [
        {
          type: 'asset',
          accounts: [
            {
              type: 'free_capital',
              accounts: this.apiData.bucket_accounts[this.bucket].filter((item) => item.type === 'asset' && item.is_bucket_free_capital),
              total: 0
            },
            {
              type: 'tied_capital',
              accounts: this.apiData.bucket_accounts[this.bucket].filter((item) => item.type === 'asset' && !item.is_bucket_free_capital),
              total: 0
            }
          ],
          total: 0
        },
        {
          type: 'liability',
          accounts: [
            {
              type: 'free_capital',
              accounts: this.apiData.bucket_accounts[this.bucket].filter((item) => item.type === 'liability' && item.is_bucket_free_capital),
              total: 0
            },
            {
              type: 'tied_capital',
              accounts: this.apiData.bucket_accounts[this.bucket].filter((item) => item.type === 'liability' && !item.is_bucket_free_capital),
              total: 0
            }
          ],
          total: 0
        }
      ]

      accounts.map((accountTypeData) => {
        this.rows.push({
          belongs_to: 'grouptitle',
          type: accountTypeData.type,
          title: accountTypeData.type,
          amount: null
        })
        accountTypeData.accounts.map((capitalData) => {
          this.rows.push({
            belongs_to: 'capital_title',
            type: accountTypeData.type,
            title: capitalData.type
          })
          capitalData.accounts.map((account) => {
            capitalData.total += account.balance
            this.rows.push({
              title: account.title,
              amount: account.balance,
              id: account.id
            })
          })
          this.rows.push({
            belongs_to: 'capital_total',
            type: 'asset',
            title: 'total',
            amount: capitalData.total
          })
          this.rows.push({
            belongs_to: 'empty',
            title: null,
            amount: null
          })
        })
        this.rows.push({
          belongs_to: 'total',
          type: accountTypeData.type,
          title: 'total',
          amount: accountTypeData.accounts[0].total + accountTypeData.accounts[1].total
        })
        this.rows.push({
          belongs_to: 'empty',
          title: null,
          amount: null
        })
      })
      this.rows.push({
        belongs_to: 'difference',
        title: 'difference',
        amount: (accounts[0].accounts[0].total + accounts[0].accounts[1].total) - (accounts[1].accounts[0].total + accounts[1].accounts[1].total)
      })

      this.busy = false
    },
    trClass (item, type) {
      let rowClass = ''
      if (item && item.belongs_to && item.belongs_to === 'total') {
        rowClass += 'summary'
      }
      if (item && item.belongs_to && item.belongs_to === 'difference') {
        rowClass += 'difference'
      }
      if (rowClass) {
        if (item && item.belongs_to) {
          rowClass += ` ${item.belongs_to}`
        }
      } else {
        if (item && item.belongs_to) {
          rowClass += `${item.belongs_to}`
        }
      }
      return rowClass
    }
  },
  watch: {
    currentCOA: {
      deep: true,
      handler () {
        this.fillTable()
      }
    },
    apiData: {
      deep: true,
      handler () {
        this.fillTable()
      }
    },
    bucket () {
      this.fillTable()
    }
  },
  created () {
    this.fillTable()
  }
}
</script>

<style lang="scss">
.four-buckets-report.tab-conservative,
.four-buckets-report.tab-aggressive,
.four-buckets-report.tab-moderate,
.four-buckets-report.tab-speculation {
  thead {
    display: none;
  }
}
.four-buckets-table {
  .capital_title, .capital_total {
    font-weight: 500;
  }
  .capital_title {
    background-color: #fff !important;
    td {
      border-top: none;
    }
  }
  .capital_total {
    border-top: 1px solid #000;
    border-bottom: 1px solid #000;
    background: #fff;
    font-weight: 900;
  }
  .summary {
    border-top: 1px solid #000;
    border-bottom: 2px solid #000;
    background: #fff;
    font-weight: 900;
  }
  .difference {
    border-top: 1px solid #000;
    border-bottom: 2px solid #000;
    background: #fff;
    font-weight: 900;
    font-size:120%;
  }
  a {
    color: #000;
  }
  tr.empty {
    background-color: #fff !important;
  }
  tr.grouptitle {
    background-color: #fff !important;

    td {
      border-top: none;
      font-size: 150%;
      border-bottom:1px solid #CACACA;
    }
  }

  tr.capital_title {
    background-color: #fff !important;

    td {
      border-top: none;
      padding-top:15px;
      font-size: 100%;
      font-weight:400;
    }
  }
}
</style>
